import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import InstarVisionCards from 'components/Software/Windows/InstarVision/Record/General/instarVisionCards';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Record/General/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "General settings for your video recording.",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Record/General/' locationFR='/fr/Software/Windows/InstarVision/Record/General/' crumbLabel="General" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQI143HsQ6CMBhF4f9V9Pkc2DSwO9A61bAQJngVF9YONPEBjCsFSWnIMRHjaLjJl9wjLAEmvwojvPr1E9k6YwynNIM4Icp6tB3Qbka7+KPcjOrCX3kXuNwXsrPmmBzIrUekeiLlA6l7pPFI/dVsNbC/3tgV7aff3kvA79KvXEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/e4706/Banner_en-InstarVision2-0_Record.avif 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/d1af7/Banner_en-InstarVision2-0_Record.avif 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/7f308/Banner_en-InstarVision2-0_Record.avif 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/e1c99/Banner_en-InstarVision2-0_Record.avif 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/76ea5/Banner_en-InstarVision2-0_Record.avif 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/f696c/Banner_en-InstarVision2-0_Record.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/a0b58/Banner_en-InstarVision2-0_Record.webp 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/bc10c/Banner_en-InstarVision2-0_Record.webp 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/966d8/Banner_en-InstarVision2-0_Record.webp 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/445df/Banner_en-InstarVision2-0_Record.webp 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/78de1/Banner_en-InstarVision2-0_Record.webp 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/882b9/Banner_en-InstarVision2-0_Record.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/81c8e/Banner_en-InstarVision2-0_Record.png 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/08a84/Banner_en-InstarVision2-0_Record.png 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/b1001/Banner_en-InstarVision2-0_Record.png 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/161ec/Banner_en-InstarVision2-0_Record.png 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---general-recording-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---general-recording-settings",
        "aria-label": "side navigation   general recording settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - General Recording Settings`}</h2>
    <p>{`You have to set a recording directory first before executing any functions that write files to your hard drive. By default, your Document folder will be selected. If your server uses more than one hard drive, you can set several directories - it is recommended to avoid using the main hard drive (Operating System Partition) for data storage.`}</p>
    <p>{`The software will then automatically jump to the next directory, in case the active hard drive does not have any space left (Overflow). Use the Up and Down Arrows to adjust the list positions and order that your directories will be used in. To be able to use network storage devices, they have to be mapped (show up like local hard drives in Windows Explorer) in Windows - `}<Link to="http://www.howtogeek.com/school/windows-network-sharing/lesson8/all/" mdxType="Link">{`see tutorial here`}</Link>{`.`}</p>
    <h3 {...{
      "id": "recording-directories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recording-directories",
        "aria-label": "recording directories permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording Directories`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "501px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/55811/Image043.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAACw0lEQVQ4y3WSO68WVRiF59/Q6n/AkobSxIREg2JIIEi08AQTKagsaCy0odA/gVpJEDwc5RLNSSi+256ZfZmZfb/MvpKZyfdxTsQnk7eZtda8k/VWQ98T0tGBljPknEvO5V2kUnIpKWepVNV1fdPAuoGY9D1lLUSUy1RKzHn8D8H7kkKJYZ6++vnBDzeufnR069rRrU/vfHHt65tXv7n9+bdfXb9/76gjRCnFORdCMCFGJV4g/skzducV//IFv/mcVzXYgs2q2a1bsDybdreutytYb0PwcY8PseT4cnCXnojP/pZXTuSHx6LSWpf/IZxh9KGkeErt9b/4d6fy7r/y9nNWSSnDPv6s2p9nn+H0QEqKwSjDhsoY45w1M7PorfoQdzbF+jCG4Lx3o68QQhDCHagBqJ1zB8PonNbaGGOtffvl81S0JwNuO9TABmy2GwCAlCrnTOmAMbHWbnc7IURK6R1m0Ot/gHoF1OtWGq2WJQ8zhGCMmRre73/O/Mup+v5J+PE4PngqBgJjSjnl4CddjDHPd5ZzXtRTZ94vWTHGylstBqx5xzpknXPWbtZrxpgQAiHYEdK0LYJoUXddJ4RQShFCOOeVUgphQroeYzJ77W635VxIKWHbDkOPMJ5fjTHGvu8ZY1prhNDejBDGiFJWSjHGrNcrxrmUsmkaOgwQwkkqRCmFENw0rfceY8wYO5gxgohSaowBADA23bOUktKhmyCLmhCyXq0AAG3bTmZj7XL685TOOWN0ngkhyAmljZ6ylNJac861NlJJpXW1/Eld10077eOcQwjutluEUEoxe6t6TFvgBC+l6J4IAilsUhhzTtXSpJpZDlBrLZXy1rTaP8bmBKtHjXjZaefDUySPoTxB8s/OMeerpYMYU4zpUOYYYynpp7W+8HC49Ji//xv9+Bnvjb/4O/vgEbv8B3vv1+G4s28AsZcgKLtZFmgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7bae86c4e092b7898d5adc26dfc5af9/e4706/Image043.avif 230w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/d1af7/Image043.avif 460w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/33655/Image043.avif 501w"],
              "sizes": "(max-width: 501px) 100vw, 501px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e7bae86c4e092b7898d5adc26dfc5af9/a0b58/Image043.webp 230w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/bc10c/Image043.webp 460w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/52d01/Image043.webp 501w"],
              "sizes": "(max-width: 501px) 100vw, 501px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7bae86c4e092b7898d5adc26dfc5af9/81c8e/Image043.png 230w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/08a84/Image043.png 460w", "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/55811/Image043.png 501w"],
              "sizes": "(max-width: 501px) 100vw, 501px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e7bae86c4e092b7898d5adc26dfc5af9/55811/Image043.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The colour, in which the directory is displayed, corresponds to the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Orange`}</strong>{`: Active recording directory.`}</li>
      <li parentName="ul"><strong parentName="li">{`Black`}</strong>{`: Backup directory - the software will automatically switch to the next backup directory once the active directory becomes unavailable or the capacity of the drive is reached.`}</li>
      <li parentName="ul"><strong parentName="li">{`Red`}</strong>{`: Directory unavailable.`}</li>
    </ul>
    <h3 {...{
      "id": "storage-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#storage-management",
        "aria-label": "storage management permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Storage Management`}</h3>
    <p>{`InstarVision can automatically delete old recordings after a certain amount of days. Activate Auto Deletion and set the number of days you need to save your recordings.`}</p>
    <h3 {...{
      "id": "video-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#video-recording",
        "aria-label": "video recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video Recording`}</h3>
    <p><strong parentName="p">{`VIDEO ENCODING`}</strong>{`: Set the file format, media codec and quality parameters for your video recordings for each channel. It is recommended to use the same encoding that is used by the camera - e.g. for INSTAR HD cameras the videos streams will be h.264/mp3 - this way, the software does not have to re-encode the stream and the processor load will be minimized. It is also recommended to use small time increments for the Create New File Every value, to minimize the damage in case a file becomes corrupted. You can also display a time stamp (On-Screen Display) if you deactivated this function inside the camera. The Soft-OSD for the MP4, MOV and MKV container further reduces the computing requirements compared to the hardcoded version.`}</p>
    <h3 {...{
      "id": "corrupted-video-files-after-a-software-crash",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#corrupted-video-files-after-a-software-crash",
        "aria-label": "corrupted video files after a software crash permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Corrupted video files after a software crash`}</h3>
    <p>{`In case the video recording function of the software is interrupted, e.g. due to a PC crash, the video will not be finalized and your video player might not be able to render it!`}</p>
    <p>{`But in most cases a free program like the `}<Link to="http://www.videolan.org/vlc/download-windows.html" mdxType="Link">{`VLC Player`}</Link>{` will still be able to play parts of the file.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      